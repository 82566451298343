.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ca8a00;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Img-bottom-center{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50px; /* Half of the image width */
    margin-right: -50px; /* Half of the image width */
  }

.Img_sticky_bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

.Img_landing_size{
    width: 288px;
    height: 288px;
}

.fade-in-out {
  animation: fadeInOut 4s infinite;
  display: inline-block;
  min-width: 240px; /* Adjust accordingly to fit the longest company name */
  text-align: left;
}

/* Specific styles for #first1000 and company names */


@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1;
  }
}

/* .Headline_landing_width{
    width: 750px;
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width:600px){
  .Img_landing_size{
    width: calc(150px + 20vmin);
    height: calc(150px + 20vmin);
}
  
}

@media only screen and (min-width:1200px){
  .Headline_landing_width{
    width: 850px;
}

.Desktop_top_neg_padding{
  margin-top: -10%;
}
}