/* Container for the full page that occupys the full screen */
.Page-div {
    background-color: #FFF3D4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    font-family: "Inter", sans-serif;
}

/* Class for red text color */
.Color-red {
    color: #FF0000;
  
}

.Color-black {
    color: #676767;
}

.Color-background {
    color: #FFF3D4;
}

.Background-color-red {
    background-color: #FF0000;
}

/* Headline text class that dynamically changes with screen size. Max font is 52 and min is 24 */
.Text-h1 {
    font-size: min(calc(14px + 4vmin), 68px);
    font-weight: 900;
    text-align: center;
    padding-left: calc(0.5em);
    padding-right: calc(0.5em);

}

.Text-h3 {
  font-size: min(calc(14px + 2vmin), 28px);
  font-weight: 400;
  text-align: center;
  padding-left: calc(0.5em);
  padding-right: calc(0.5em);

}

.Text-h6 {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding-left: calc(0.5em);
    padding-right: calc(0.5em);
}

.Bold {
    font-weight: bold;
  }

.Img_sticky_bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .Margin_zero{
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    .Page-div {
    justify-content: normal;
  }
  .Mobile_top_padding{
    padding-top: 10%;
  }

}


    
