.btn-free-access{
    width: 473px;
    height: 69px;
    border-style: none;
    border-radius: 10px;
    margin-top:5px; 
    font-weight:700;
    font-size:16px;
    cursor: pointer;
}

  .email-capture-landing{
    width: 473px;
    height: 69px;
    border-style: none;
    border-radius: 10px;
    margin-top:20px;
    text-indent: 10px ;
    color:#2F2651;
    font-weight: 500;
}

.email-capture-landing::placeholder{
    color:#656B73;
    font-weight: 400;
}

@media only screen and (max-width:600px){
    .btn-free-access{
        width: 350px;
        height:45px;
        border-radius: 5px;
    }

    .email-capture-landing{
        width: 350px;
        height:45px;
        border-radius: 5px;
    }
  }